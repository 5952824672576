import { Checkbox } from 'antd'

const ExtraServiceCheckbox = ({
	data,
	defaultValue = [],
	onChange,
	onCheckboxChange,
	disableExtra = [],
	...others
}) => {
	const handleChange = (e) => {
		onChange(e)
	}
	return (
		<div className="extra__service__checkbox">
			<div className="extra__service-container">
				<Checkbox.Group
					defaultValue={defaultValue}
					style={{ width: '100%' }}
					onChange={handleChange}
					{...others}
				>
					{data?.map((e) => (
						<div key={e.serviceID} className="extra__service-item">
							<Checkbox value={e.serviceID} disabled={disableExtra.includes(e.serviceID)}>
								<span className="item-text">{`${e.serviceID} - ${e.serviceName}`}</span>
							</Checkbox>
						</div>
					))}
				</Checkbox.Group>
			</div>
		</div>
	)
}

export default ExtraServiceCheckbox
